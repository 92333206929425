<template>
    <main>
        <section class="stretch-section">
            <h1>Projects</h1>
            <Cards :collection="this.projects" showFilters/>
        </section>
    </main>
</template>

<script>
    import Cards from '@/components/Cards.vue'

    export default {
        components: {
            Cards
        },
        inject: ['projects']
    }
</script>

<style>

</style>